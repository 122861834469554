* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Gantari', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #231f20;
  background-color: #fff;
}

h3 {
  font-size: 34px;
  font-weight: 700;
  line-height: 51px;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  margin: 0;
}

a {
  text-decoration: none;
  color: #231f20;
  display: inline-block;
  transition: 0.4s;
}

a:hover {
  text-decoration: underline;
  transition: 0.4s;
}

input:focus,
button:focus {
  outline: none;
}

.form-control,
input {
  border: 1px solid #ececec;
  font-size: 14px;
  height: 50px;
  border-radius: 5px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

body iframe {
  position: static !important;
}

.content_box .form-control:focus {
  color: #000;
  box-shadow: none;
}

.form-label {
  font-weight: 700;
  margin-bottom: 12px;
}

.form-control:focus {
  color: #fff;
  opacity: 1;
  background-color: inherit;
  border-color: inherit;
  outline: 0;
  box-shadow: 0;
}

.signUpBox {
  margin-top: 20px;
}

.signUpBox ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signUpBox ul li a {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  color: #506cb4;
}

.signUpBox ul li a:hover {
  color: #29aee4;
}

/* .signUpBox ul li > * {
  background: linear-gradient(293.3deg, #506CB4 4.8%, #29AEE4 95.03%);  
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
} */

/* .signUpBox ul li:hover > * {
  background: linear-gradient(293.3deg, #29AEE4 4.8%, #506CB4 95.03%);  
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
} */

.btn-primary {
  background: linear-gradient(293.3deg, #506cb4 4.8%, #29aee4 95.03%);
  color: #fff;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 6px 20px;
  width: 100%;
}

.btn-delete-profile {
  background: linear-gradient(293.3deg, #506cb4 4.8%, #29aee4 95.03%);
}

.animations {
  animation: beat 1s ease-in-out infinite;
}

@keyframes beat {
  0% {
    scale: 1;
  }
  50% {
    scale: 0.94;
  }
  100% {
    scale: 1;
  }
}

@keyframes auras {
  0% {
    box-shadow: 0 0 90px 70px red;
  }
  14.285714285714285714285714285714% {
    box-shadow: 0 0 90px 70px orange;
  }
  28.571428571428571428571428571429% {
    box-shadow: 0 0 90px 70px yellow;
  }
  42.857142857142857142857142857143% {
    box-shadow: 0 0 90px 70px green;
  }
  57.142857142857142857142857142857% {
    box-shadow: 0 0 90px 70px blue;
  }
  71.428571428571428571428571428571% {
    box-shadow: 0 0 90px 70px indigo;
  }
  85.714285714285714285714285714286% {
    box-shadow: 0 0 90px 70px violet;
  }
  100% {
    box-shadow: 0 0 90px 70px red;
  }
}

.innerBox .table-striped tbody tr:nth-of-type(2n + 1) {
  background: #f4f7ff;
}

.form-control::placeholder {
  color: #515151;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.table thead th {
  border-bottom: 1px solid #ececec;
}

footer h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 30px;
}

footer .fourth-column h5 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 55px;
  width: 160px;
}

.fourth-column h5 .line:after {
  bottom: -38px;
}

footer .btn-primary:hover {
  background-color: #f8e641;
  background: linear-gradient(293.3deg, #29aee4 4.8%, #506cb4 95.03%);
}

.fourth-column form {
  position: relative;
}

.fourth-column form button {
  position: absolute;
  top: 5px;
  right: 6px;
  width: auto;
  margin: 0;
}

.content_box .btn-primary {
  width: auto;
}

small {
  display: block;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
}

.form-check {
  position: relative;
  display: flex;
  padding-left: 1.25rem;
  align-items: center;
}

.form-control,
input {
  color: #000;
}

.form-check-input {
  position: absolute;
  margin-top: 0;
  margin-left: -1.25rem;
}

.filesList.form-control.file_list_main {
  height: inherit;
}

.form-group .profile-deletebutton {
  background-color: #fff !important;
}

@media only screen and (max-width: 992px) {
  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 26px;
    line-height: 36px;
  }

  h5 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 640px) {
  h2 {
    font-size: 24px;
    line-height: 32px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error.Toastify__toast--close-on-click {
  display: block !important;
  opacity: 1 !important;
}
