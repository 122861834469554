@media only screen and (max-width: 1400px) {
  .video_section:after,
  .faq .accordion:after {
    width: 280px;
    height: 280px;
    right: 0;
  }
  .highlight-image {
    width: auto;
    left: 0;
  }
  /* 
  .menu-bars img {
    filter: brightness(0);
  } */
}

@media only screen and (max-width: 1399px) {
  .elipse {
    right: 90px;
  }

  .innerBox.tables input {
    color: #000;
    font-size: 16px;
  }

  .innerBox.tables .form-control::placeholder {
    color: #000;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1365px) {
  .elipse {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .highlight-image {
    transform: none;
    position: static;
    margin: 0 auto 20px;
  }

  .banner ul.labelList li {
    width: auto;
    margin: 0 20px 20px 0;
  }

  .bannerBox {
    display: none;
  }

  .banner .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1138px) {
  .link_box::before {
    left: 0;
  }
  .margin_box .form-check {
    padding: 17px 20px;
  }

  .secureList li:nth-child(3) {
    margin-right: 18px;
  }

  .popularFeatures button {
    color: #231f20 !important;
  }

  .popularFeatures button span {
    color: #231f20 !important;
  }
}

@media only screen and (max-width: 1138px) {
  .slideBox.forteen img,
  .slideBox.tweleve img,
  .slideBox.eleven img,
  .slideBox.eight img,
  .slideBox.thriteen img,
  .slideBox.nine img,
  .slideBox.ten img {
    height: inherit;
  }

  .slideBox.one,
  .slideBox.two,
  .slideBox.three,
  .slideBox.four,
  .slideBox.five,
  .slideBox.six,
  .slideBox.seven,
  .slideBox.eight,
  .slideBox.nine,
  .slideBox.ten,
  .slideBox.eleven,
  .slideBox.tweleve,
  .slideBox.thriteen,
  .slideBox.forteen {
    margin: 32px 0 0 0;
  }

  .slideBox.eight {
    display: none;
  }

  .bannerBox .App-logos {
    display: none;
  }

  .sliderBox {
    position: static;
    background-color: #fff;
  }
}

@media only screen and (max-width: 991px) {
  /* .main_navigation_menu {
    display: none;
  } */

  .faq .accordion-button.collapsed::after {
    background-size: 15px;
  }

  nav ul li:last-child {
    display: none;
  }

  .banner .line::after {
    bottom: -17px;
  }

  .secureSection .line::after {
    bottom: -20px;
  }

  .featuresBox .line::after {
    bottom: -20px;
  }

  .solutionBox .line::after {
    bottom: -19px;
  }

  .banner ul.labelList li {
    width: 50%;
    margin: 0 0 20px 0;
  }

  .popularFeatures .line::after {
    bottom: -20px;
  }

  .fourth-column h5 .line::after {
    bottom: -25px;
  }

  .line::after {
    width: 100px;
    height: 35px;
    bottom: -17px;
  }

  .header_box {
    padding: 13px 40px;
  }

  .pdf_format.full_width .right_column {
    padding: 0;
  }

  .home {
    overflow: hidden;
  }

  .navbar-nav {
    display: none;
  }

  .solutionBox {
    margin-top: 60px;
  }

  .banner h2 {
    font-size: 34px;
    line-height: 42px;
  }

  .banner p {
    font-size: 20px;
    line-height: 30px;
  }

  .banner {
    padding: 60px 0;
  }

  .navbar-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .nav-meu-items {
    margin-top: 50px;
  }

  .nav-meu-items li:last-child a,
  .nav-meu-items li:last-child span {
    text-transform: uppercase;
  }

  .nav-text a {
    font-size: 16px;
    color: #000;
  }

  nav ul li {
    margin-right: 0;
  }

  nav ul li:nth-child(6) {
    text-transform: uppercase;
  }

  .mobile {
    display: block;
  }

  .banner h1 {
    padding-right: 0;
    font-size: 36px;
    line-height: normal;
    margin-bottom: 15px;
  }

  .banner a {
    padding: 12px 20px;
  }

  .banner_right {
    justify-content: flex-start;
    width: 100%;
    margin-top: 40px;
  }

  .navbar-toggle {
    z-index: 9;
  }

  .eSignSection .swiper-pointer-events {
    z-index: -1;
  }

  .video_section {
    width: 100%;
  }

  .video_content {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -80px;
    margin: 0 auto;
    width: 100%;
    justify-content: space-around;
  }

  .esignature.product_tour {
    position: static;
  }

  .esignature {
    position: static;
    width: 49%;
    margin: 0 1%;
  }

  .container {
    max-width: 1140px;
  }

  .businessSection h2 {
    margin-bottom: 25px;
  }

  .businessSection ul li {
    margin-right: 30px;
  }

  .businessSection {
    padding: 100px 0 40px 0;
  }

  .faq {
    padding: 40px 0;
  }

  .features_box {
    width: 100%;
  }

  .whiteBox {
    background-color: #fff;
    height: 100%;
  }

  .popularFeatures .accordion {
    margin-top: 30px;
  }

  .popularFeatures button {
    font-size: 16px;
  }

  .popularFeatures .accordion img {
    width: 25px;
  }

  .popularFeatures button.accordion-button.collapsed::after {
    top: 15px;
    width: 16px;
    height: 16px;
    background-size: contain;
    z-index: 9;
  }

  .pdf_format {
    padding: 0;
  }

  .left_column a {
    width: 33.3%;
  }

  .min_height .innerBox {
    width: 100%;
  }

  .popularFeatures button.accordion-button::after {
    top: 15px;
    width: 16px;
    height: 16px;
    background-size: contain;
  }

  .popularFeatures {
    padding: 40px 0;
  }

  .popularFeatures .accordion-body p {
    padding-left: 0;
  }

  .faq h2.faq_title {
    margin-bottom: 30px;
  }

  .faq .accordion-collapse.collapse.show::before {
    display: none;
  }

  .faq .accordion-collapse.collapse.show::after {
    display: none;
  }

  .faq .accordianBoxes {
    padding: 0 12px;
  }

  .faq .accordion-item {
    padding: 20px 50px 20px 20px;
    margin-bottom: 25px;
  }

  .faq .accordion-button::before {
    width: 28px;
    height: 28px;
    top: 2px;
  }
  .faq .accordion-button::after {
    width: 15px;
    height: 15px;
    top: 8px;
    right: -32px;
    background-size: contain;
  }

  .row_box {
    padding: 20px 0;
    flex-wrap: wrap;
  }

  .getStarted h2 {
    margin-bottom: 10px;
  }

  footer {
    padding-top: 40px;
  }

  .border_box {
    padding: 15px;
  }
  .form_boxes.page_not_found {
    width: 100%;
  }
  .innerBox.tables {
    padding: 20px;
    display: block;
    white-space: nowrap;
    overflow-x: auto;
  }

  .innerBox.tables .form_table_section {
    white-space: normal;
  }

  .innerBox table {
    min-width: 750px;
  }
  .react-responsive-modal-modal {
    max-width: 80%;
  }

  .solutionBox li {
    display: flex;
  }

  .solutionBox li svg {
    flex-shrink: 0;
  }

  .faqText h3 {
    margin-bottom: 35px;
  }

  .popularFeatures .site_btn {
    margin-top: 30px;
  }

  .arrows {
    display: none;
  }

  .faqText p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin: 10px 0 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .popularFeatures button {
    justify-content: flex-start;
    text-align: left;
  }

  .popularFeatures h2 {
    padding-right: 30px;
  }

  .banner ul.labelList {
    margin-bottom: 10px;
  }

  .popularFeatures button.accordion-button.collapsed::after {
    top: 10px;
    right: -25px;
  }

  .secureSection {
    padding: 60px 0;
  }

  .popularFeatures button.accordion-button::after {
    top: 10px;
    right: -25px;
  }
}

@media only screen and (max-width: 767px) {
  .view_all {
    justify-content: flex-start;
    margin-top: 15px;
  }

  .optionContainer .button_box.mb-4 {
    right: inherit;
    position: static;
    justify-content: flex-start;
  }

  .solutionBox {
    margin-top: 40px;
  }

  .solutionImg img {
    right: 0;
  }

  .secureList li {
    margin-bottom: 20px;
  }

  .main_area .left_column {
    width: 30%;
  }

  .main_area .right_column {
    width: 70%;
  }

  .view_all a {
    padding: 10px 25px;
  }

  .link_box::before {
    display: none;
  }

  .link_box {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-top: 20px;
  }

  .link_box a,
  .banner a.nav-link,
  .site_btn,
  .form_boxes a.nav-link {
    padding: 10px 25px;
    font-size: 14px;
  }

  .row_box p {
    font-size: 16px;
    line-height: 26px;
  }

  .getStarted {
    padding-bottom: 60px;
  }

  .first-column p {
    width: 100%;
  }

  .first-column ul {
    display: flex;
    margin-top: 25px;
  }

  footer h5 {
    margin-bottom: 15px;
  }

  .col-md-2.second-column {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .second-column ul li:last-child a {
    margin-bottom: 0;
  }

  .fourth-column {
    border-top: 1px solid #333;
    padding-top: 20px;
    margin-top: 10px;
  }

  .footerBottom {
    margin-top: 15px;
  }

  .swiper {
    padding: 0 0 10px 10px;
  }

  .whiteBox {
    height: calc(100% - 100px);
  }

  .features_box {
    position: static;
  }

  .testimonial_video {
    margin-bottom: 20px;
  }

  .faq .accordion-item {
    padding: 20px 50px 20px 20px;
  }
  .faq .accordion-button::after {
    right: -34px;
  }
  .faq .accordion-button::before {
    right: -40px;
  }
  .center_button {
    margin-bottom: 20px;
    padding: 0;
  }

  .center_button button {
    margin-bottom: 0;
  }
  .top_table_boxes ul {
    flex-wrap: wrap;
  }
  .top_table_boxes ul li {
    width: 100%;
    margin: 0 0 10px;
  }
  .bottom_table ul {
    justify-content: flex-start;
  }
  .bottom_table ul li:last-child {
    padding-right: 20px;
  }
  .form_table_section .text-secondary {
    padding-right: 20px;
  }
  .businessSection {
    padding: 20px 0 40px 0;
  }
  .popularFeatures .accordion-item {
    padding: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .video_content {
    flex-wrap: wrap;
  }

  .innerBox_row .statistics h3 {
    margin: 0 0 10px 0;
  }

  .secureSection .heightfix ul li p {
    margin-bottom: 0;
  }

  .faqText span.borders {
    font-size: 16px;
    padding: 0;
  }

  .faqText span.borders::before {
    width: 43px;
    top: 9px;
    display: none;
  }

  .faqText p {
    font-size: 18px;
  }

  .esignature {
    width: 100%;
    margin: 0 0 10px 0;
  }

  .video_section {
    border: 0;
    height: inherit;
  }

  .video_content {
    position: static;
    margin-top: 30px;
  }

  .video_section .video_player {
    height: 422px;
    border: 1px solid #000000;
  }

  .businessSection {
    padding: 20px 0 40px 0;
  }

  .businessSection ul li img {
    margin: 0 auto;
  }

  .eSignSection .swiper-pointer-events {
    margin: 0;
    padding: 0 30px;
  }

  .faq .accordianBoxes {
    width: 100%;
  }

  .pdf_format {
    padding: 0;
  }

  .faq .accordianBoxes {
    padding: 0;
  }

  .pdf_format {
    flex-direction: column;
  }

  .main_area .left_column {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: pre;
    margin-bottom: 20px;
  }

  .pdf_row {
    flex-shrink: 0;
    padding: 15px;
    width: auto;
    margin-right: 20px;
    border: 0;
  }

  .pdf_row.last_child,
  .bottom_border {
    display: none;
  }

  .pdf_format.full_width .right_column {
    width: 100%;
    padding: 30px;
  }

  .main_area .right_column {
    width: 100%;
  }

  .pdf_row h6 {
    margin-bottom: 15px;
    display: block;
    text-align: left;
    width: 100%;
  }
  .banner_right {
    display: block;
    margin: 0;
  }
  .footerBottom {
    text-align: center;
  }
  .header_box {
    padding: 10px 15px;
  }
  .header_dashboard_logo ul {
    margin-left: 10px;
  }
  .header_dashboard_logo ul li {
    margin-right: 10px;
  }

  .header_dashboard_logo ul li:nth-child(3) {
    display: none;
  }

  .header_dashboard_logo ul li a {
    font-size: 12px;
  }

  .header_dashboard_logo ul li a,
  .header_dashboard_logo ul li span {
    font-size: 12px;
  }

  .left_column {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .pdf_format.full_width .left_column a {
    width: 33%;
  }
  .center_button button {
    width: 100%;
  }
  .left_column .sidebar img {
    width: 30px;
  }
  .left_column .sidebar {
    padding: 10px 20px;
  }
  .pdf_format {
    margin-top: 67px;
  }
  .feature.content_box h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .feature.content_box p {
    font-size: 16px;
    line-height: 28px;
  }
  .upperBox h4,
  h4 {
    font-size: 18px;
    line-height: 30px;
  }
  .businessSection ul li {
    margin-right: 10px;
  }
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary:not(:disabled):not(.disabled):active {
    color: #000000;
    background-color: #f8e641;
    box-shadow: none;
  }

  .react-responsive-modal-modal {
    max-width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .join-text {
    margin-top: 30px;
  }

  .form_box.pdf.display_form_data {
    margin-top: 100px !important;
    width: 90%;
  }

  .form_box.pdf.display_form_data .form_main_box .d-flex {
    flex-wrap: wrap;
  }

  .form_box.pdf.display_form_data {
    word-break: break-all;
  }

  .download_btn.mt-2.text-center a {
    margin: 0 8px 8px 8px !important;
  }

  .solution-list {
    margin-bottom: 30px;
  }

  .innerBox_row {
    margin: 0;
  }

  #formBasicEmail {
    margin-bottom: 20px;
  }

  .left_column a {
    width: 33.3%;
  }

  .right_column {
    padding: 30px;
  }

  .min_height .innerBox {
    padding: 0 30px;
  }

  .min_height .innerBox_row .col-md-6 {
    margin-bottom: 30px;
  }

  .innerBox_row h3 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .right_column.profiles .innerBox.tables .P2P h1 {
    margin: 10px 0 20px 0 !important;
    font-size: 30px !important;
  }

  .right_column.profiles .innerBox.tables .P2P .form-group {
    width: 100%;
  }

  .right_column.profiles .innerBox.tables .Escrow.P2P button {
    width: 100% !important;
    margin: 0 0 0 17px !important;
  }

  .popularFeatures button {
    line-height: 26px;
  }

  .popularFeatures .accordion-body p {
    margin-top: 5px;
  }

  .copy_right {
    text-align: center;
    margin-bottom: 5px;
  }

  .footerBottom ul {
    text-align: center;
    justify-content: center;
  }

  .eSignSection .swiper img {
    margin: 0 auto 15px auto;
  }

  .eSignSection .swiper-slide {
    padding: 30px 16px 20px 16px;
  }

  .menu-bars {
    font-size: 18px;
  }

  .menu-bars img {
    filter: brightness(0);
  }

  .logo a img {
    width: 135px;
  }

  .header_dashboard_logo ul li a,
  .header_dashboard_logo ul li span {
    font-size: 12px !important;
  }

  .pdf_format h1 {
    margin-top: 40px;
    font-size: 26px;
  }

  .header_dashboard_logo .menu-bars img {
    width: 135px;
  }

  .header_account ul li {
    margin-right: 10px;
  }

  .header_account ul li.login a {
    padding: 5px 10px;
  }

  .mobile {
    padding-right: 0;
  }

  .navigation {
    padding: 15px 0;
  }

  .logo a {
    margin: 0;
    width: 100%;
  }
  h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .banner ul.labelList li {
    width: 100%;
  }

  .secureSection {
    padding: 40px 0;
  }

  .secureSection h3 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .home .text-gradient h3 > span,
  .text-gradient h3,
  .text-gradient h3 span {
    font-size: 22px;
    line-height: 32px;
  }

  .solutionBox h3 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 30px;
  }

  .solutionBox li {
    margin-bottom: 20px;
  }

  .text-gradient h3 > span {
    font-size: 22px;
    line-height: 32px;
  }

  .secureList li {
    margin-bottom: 10px;
  }

  .scrolls {
    margin: 0;
  }

  .heightfix {
    width: 100%;
    padding: 0;
  }

  .secureSection .heightfix ul {
    width: 100%;
    padding-top: 25px;
  }

  .banner h2 {
    font-size: 28px;
    line-height: normal;
  }

  .banner p {
    font-size: 18px;
    line-height: 28px;
  }

  .banner ul.labelList li {
    margin-bottom: 15px;
  }

  .banner ul.labelList li span {
    font-size: 16px;
  }

  .banner {
    padding: 40px 0;
  }

  .secureSection .heightfix ul li h4 {
    margin-bottom: 10px;
  }

  footer .footerBottom p {
    margin-bottom: 12px;
  }

  .signer_title {
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
  }

  .form_box.pdf .signer_title h5 {
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
  }

  .form_box.pdf .signer_title button {
    margin: 0;
    width: 100%;
    justify-content: center;
  }
}

@media only screen and (max-width: 480px) {
  .left_column .sidebar img {
    width: 20px;
  }
  .content_box.feature,
  .solution.content_box,
  .content_box {
    overflow: hidden;
  }

  .popularFeatures .accordion-body p {
    padding-left: 32px;
  }
  input[type='date'] {
    height: auto;
  }
  .innerBox table {
    width: 600px;
  }
  .table-bordered thead td,
  .table-bordered thead th {
    width: auto !important;
  }
}
